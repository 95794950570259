import axios from 'axios';


const serverUrl = process.env.REACT_APP_SERVER_URL;


export const LoginAPI = async (username, password) => {
    let url = `${serverUrl}/api/login`;

    const payload = {
        username,
        password,
    };

    try {
        const loginApiResponse = await axios.post(url, payload);
        if (loginApiResponse.status === 200) {
            return loginApiResponse;
        } else {
            return loginApiResponse;
        }
    } catch (error) {
        return false;
    }
};


export const SignUpAPI = async (username, password, confirm_password, firstname, lastname) => {
    let url = `${serverUrl}/api/signup`;

    const payload = {
        username,
        password,
        confirm_password,
        firstname,
        lastname
    };

    try {
        const signUpApiResponse = await axios.post(url, payload);
        if (signUpApiResponse.status === 200) {
            return signUpApiResponse;
        } else {
            return signUpApiResponse;
        }
    } catch (error) {
        return false;
    }
};


export const UserForgetPassword = async (register_email) => {
    let url = `${serverUrl}/api/forget_password`;

    const payload = {
        register_email
    };

    try {
        const forgetPasswordApiResponse = await axios.post(url, payload);
        if (forgetPasswordApiResponse.status === 200) {
            return forgetPasswordApiResponse;
        } else {
            return forgetPasswordApiResponse;
        }
    } catch (error) {
        return false;
    }
};


export const UserNewPassword = async (new_password, confirm_new_password, user_id) => {
    let url = `${serverUrl}/api/new_password`;
    
    const payload = {
        new_password,
        confirm_new_password,
        user_id
    };

    try {
        const newPasswordApiResponse = await axios.post(url, payload);
        if (newPasswordApiResponse.status === 200) {
            return newPasswordApiResponse;
        } else {
            return newPasswordApiResponse;
        }
    } catch (error) {
        return false;
    }
};


export const GetAccessToken = async (refresh_token) => {
    let url = `${serverUrl}/api/login`;

    const headers = {
        Authorization: `Bearer ${refresh_token}`
    };

    try {
        const accessTokenApiResponse = await axios.get(url, { headers });
        if (accessTokenApiResponse.status === 200) {
            return accessTokenApiResponse;
        } else {
            return accessTokenApiResponse;
        }
    } catch (error) {
        return false;
    }
};


export const DlomValues = async (year_month, market_value, block_size, sic_code, calculation_type) => {
    let accessToken = localStorage.getItem('access_token');
    let url = `${serverUrl}/api/dlom_calculator`;
    let calculation_export_csv = false

    const payload = {
        year_month,
        market_value,
        block_size,
        calculation_export_csv,
        sic_code,
        calculation_type
    };
    
    const headers = {
        Authorization: `Bearer ${accessToken}`
    }

    try {
        const calculationApiResponse = await axios.post(url, payload, { headers });
        if (calculationApiResponse.status === 200) {
            await new Promise(resolve => setTimeout(resolve, 1000));
            return calculationApiResponse;
        } else {
            return calculationApiResponse;
        }
    } catch (error) {
        if (error.CalculationApiResponse && error.CalculationApiResponse.status === 401) {
            return error.CalculationApiResponse
        } else {
            return error;
        }
    }
};


export const UserProfileDetails = async () => {
    let accessToken = localStorage.getItem('access_token');
    let user_id = localStorage.getItem('user_id');
    let url = `${serverUrl}/api/user_profile`;

    const payload = {
        user_id
    };

    const headers = {
        Authorization: `Bearer ${accessToken}`
    }

    try {
        const userProfileDetailsResponse = await axios.post(url, payload, { headers });
        if (userProfileDetailsResponse.status === 200) {
            return userProfileDetailsResponse;
        } else {
            return userProfileDetailsResponse;
        }
    } catch (error) {
        return error;
    }
};


export const StandardIndustrialCodeList = async () => {
    let accessToken = localStorage.getItem('access_token');
    let url = `${serverUrl}/api/standard_industrial_code`;

    let headers = {
        Authorization: `Bearer ${accessToken}`
    }

    try {
        const standardIndustrialCodeResponse = await axios.get(url, { headers });
        if (standardIndustrialCodeResponse.status === 200) {
            return standardIndustrialCodeResponse
        } else {
            return standardIndustrialCodeResponse
        }
    } catch (error) {
        return error
    }
}


export const TickerDetails = async (ticker_year, ticker_symbol, ticker_block_size) => {
    let accessToken = localStorage.getItem('access_token')
    let url = `${serverUrl}/api/ticker_details`;
    let export_csv = false

    const headers = {
        Authorization: `Bearer ${accessToken}`
    }
    
    const payload = {
        ticker_year,
        ticker_symbol,
        ticker_block_size,
        export_csv
    };

    try {
        const tickerDetailsApiResponse = await axios.post(url, payload, { headers })
        if (tickerDetailsApiResponse.status === 200) {
            await new Promise(resolve => setTimeout(resolve, 1000));
            return tickerDetailsApiResponse;
        }
        else {
            return tickerDetailsApiResponse;
        }
    } catch (error) {
        return error;
    }
}


export const TradingStockPrice = async (trading_date, ticker_symbol) => {
    let accessToken = localStorage.getItem('access_token');
    let url = `${serverUrl}/api/trading_stock_details`;
    let trading_details_export_csv = false

    let headers = {
        Authorization: `Bearer ${accessToken}`
    }

    let payload = {
        trading_date,
        ticker_symbol,
        trading_details_export_csv
    };

    try {
        const tradingStockPriceApiResponse = await axios.post(url, payload, { headers })
        if (tradingStockPriceApiResponse.status === 200) {
            return tradingStockPriceApiResponse
        } else {
            return tradingStockPriceApiResponse
        }
    } catch (error) {
        return error
    }
}


export const ExportTickerDetails = async (ticker_year, ticker_symbol, ticker_block_size) => {
    let accessToken = localStorage.getItem('access_token')
    let export_csv = true
    let url = `${serverUrl}/api/ticker_details`;

    const headers = {
        Authorization: `Bearer ${accessToken}`
    }
    
    const payload = {
        ticker_year,
        ticker_symbol,
        ticker_block_size,
        export_csv
    };

    try {
        const exportTickerDetailsApiResponse = await axios.post(url, payload, { headers })
        if (exportTickerDetailsApiResponse.status === 200) {
            await new Promise(resolve => setTimeout(resolve, 2000));
            return exportTickerDetailsApiResponse;
        }
        else {
            return exportTickerDetailsApiResponse;
        }
    }
    catch (error) {
        return error;
    }
}


export const ExportTradingStockPrice = async (trading_date, ticker_symbol) => {
    let accessToken = localStorage.getItem('access_token');
    let url = `${serverUrl}/api/trading_stock_details`;
    let trading_details_export_csv = true

    let headers = {
        Authorization: `Bearer ${accessToken}`
    }

    let payload = {
        trading_date,
        ticker_symbol,
        trading_details_export_csv
    };

    try {
        const exportTradingStockPriceApiResponse = await axios.post(url, payload, { headers })
        if (exportTradingStockPriceApiResponse.status === 200) {
            return exportTradingStockPriceApiResponse
        } else {
            return exportTradingStockPriceApiResponse
        }
    } catch (error){
        return error
    }
}


export const ExportCalculationValues = async (year_month, market_value, block_size, sic_code, calculation_type) => {
    let accessToken = localStorage.getItem('access_token');
    let url = `${serverUrl}/api/dlom_calculator`;
    let calculation_export_csv = true
    
    const payload = {
        year_month,
        market_value,
        block_size,
        sic_code,
        calculation_export_csv,
        calculation_type
    };

    const headers = {
        Authorization: `Bearer ${accessToken}`
    }

    try {
        const exportCalculationApiResponse = await axios.post(url, payload, { headers });
        if (exportCalculationApiResponse.status === 200) {
            await new Promise(resolve => setTimeout(resolve, 2000));
            return exportCalculationApiResponse;
        } else {
            return exportCalculationApiResponse;
        }
    } catch (error) {
        if (error.exportCalculationApiResponse && error.exportCalculationApiResponse.status === 401) {
            return error.exportCalculationApiResponse
        } else {
            return error;
        }
    }
};

