import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Login from "../Components/LoginSignup/Login";
import SignUp from "../Components/LoginSignup/SignUp";
import ForgetPassword from "../Components/LoginSignup/ForgetPassword";
import NewPassword from "../Components/LoginSignup/NewPassword";
import 'bootstrap/dist/css/bootstrap.min.css';
import Dashboard from "../Components/Dlom/Dashboard";
import TickersList from "../Components/Dlom/TickersList";
import UserProfile from "../Components/Dlom/UserProfile";
import TradingStocks from "../Components/Dlom/TradingStocks";
import PrivateRoute from "./PrivateRoute";
import StandardIndustrialCode from "../Components/Dlom/StandardIndustrialCodes";
import UserApiKey from "../Components/Dlom/UserApiKey";

export default function ApplicationRoutes() {

  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Login />} />
        <Route path="/sign_up" element={<SignUp />} />
        <Route path="/forgot_password" element={<ForgetPassword />} />
        <Route path="/new_password" element={<NewPassword />} />
        <Route path="/dashboard" element={<PrivateRoute component={Dashboard} />} />
        <Route path="/user_profile" element={<PrivateRoute component={UserProfile} />} />
        <Route path="/tickers_data" element={<PrivateRoute component={TickersList} />} />
        <Route path="/trading_stocks" element={<PrivateRoute component={TradingStocks} />} />
        <Route path="/standard_industrial_code" element={<PrivateRoute component={StandardIndustrialCode} />} />
        <Route path="/user_api_key" element={<PrivateRoute component={UserApiKey} />} />
      </Routes>
    </BrowserRouter>
  );
}
