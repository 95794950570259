import React, { useState } from 'react';
import { TradingStockPrice } from '../../ServerApi/server';
import TopNavbar from './TopNavbar';
import LeftNavbar from './LeftNavbar';
import { GetAccessToken } from '../../ServerApi/server';
import { BsFillSaveFill } from "react-icons/bs";
import { FaDownload } from "react-icons/fa"
import { ExportTradingStockPrice } from '../../ServerApi/server';
import { tradingStocksExportFileName } from '../../Helper/helpers';
import RefusedPopup from './RefusedPopupMessage';
import LoadingScreen from './LoadingScreen';
import { VALIDATION_ALERT_MESSAGES } from '../../Helper/constants';
import { Row, Col, Container, CardTitle, CardHeader, Card, CardBody, FormGroup, Input, Label, Button } from 'reactstrap';


export default function TradingStocks() {
    const [stockDate, setStockDate] = useState('');
    const [tradingTickerSymbol, setTradingTickerSymbol] = useState('');
    const [tradingStockPriceDetails, setTradingStockPriceDetails] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [showPopupRefused, setShowPopupRefused] = useState(false)
    const [popupRefusedMessage, setPopupRefusedMessage] = useState(false)
    const [isLoading, setIsLoading] = useState(false);
    const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
        setCurrentPage(1);
    };

    const [errors, setErrors] = useState({
        stockDate: ''
    });

    const tradingStocksValidate = () => {
        let valid = true;
        const tradingStockDetails = {
            stockDate: ''
        };

        if (!stockDate && !tradingTickerSymbol) {
            tradingStockDetails.stockDate = VALIDATION_ALERT_MESSAGES.tradingDateValidator;
            valid = false;
        }
        setErrors(tradingStockDetails);
        return valid;
    }

    const allTradingStockPriceDetails = async () => {
        if (!tradingStocksValidate()) {
            return;
        }
        setIsLoading(true);
        const tradingStockPriceResponse = await TradingStockPrice(stockDate, tradingTickerSymbol);
        try {
            setIsLoading(false);
            if (tradingStockPriceResponse['data'] && tradingStockPriceResponse['data']['client_status_code'] === 200) {
                setTradingStockPriceDetails(tradingStockPriceResponse['data']['trading_stock_price_details']);
            } else if (tradingStockPriceResponse['response'] && tradingStockPriceResponse['response']['data']['client_status_code'] === 401) {
                let refreshToken = localStorage.getItem('refresh_token')
                const tradingStocksAccessTokenResponse = await GetAccessToken(refreshToken);
                localStorage.setItem('access_token', tradingStocksAccessTokenResponse['data']['data']['access_token']);
                allTradingStockPriceDetails();
            } else {
                setPopupRefusedMessage(VALIDATION_ALERT_MESSAGES.tryAgainAlert);
                setShowPopupRefused(true);
                setTimeout(() => {
                    setShowPopupRefused(false);
                }, 2000)
            }
        } catch (error) {
            setPopupRefusedMessage(VALIDATION_ALERT_MESSAGES.connectionRefusedAlert);
            setShowPopupRefused(true);
            setTimeout(() => {
                setShowPopupRefused(false);
            }, 2000)
        }
    };

    const filteredTickers = tradingStockPriceDetails
        ? tradingStockPriceDetails.filter((ticker) => {
            let sharesOutstanding = ticker.share_class_shares_outstanding;
            return (
                (ticker.ticker?.toLowerCase()?.includes(searchTerm.toLowerCase()) ||
                    ticker.date?.toLowerCase()?.includes(searchTerm.toLowerCase()) ||
                    (sharesOutstanding !== null &&
                        sharesOutstanding !== undefined &&
                        sharesOutstanding.toString().includes(searchTerm)))
            );
        })
        : [];

    let indexOfLastItem = currentPage * itemsPerPage;
    let indexOfFirstItem = indexOfLastItem - itemsPerPage;
    let currentTickers = filteredTickers.slice(indexOfFirstItem, indexOfLastItem);
    let totalPages = Math.ceil(filteredTickers.length / itemsPerPage);

    const renderPageNumbers = () => {
        const pageNumbers = [];
        const maxVisiblePages = 3;
        let startPage = Math.max(currentPage - Math.floor(maxVisiblePages / 2), 1);
        let endPage = startPage + maxVisiblePages - 1;
    
        if (endPage > totalPages) {
          endPage = totalPages;
          startPage = Math.max(endPage - maxVisiblePages + 1, 1);
        }
    
        for (let i = startPage; i <= endPage; i++) {
          pageNumbers.push(
            <li className={`page-item ${currentPage === i ? 'active' : ''}`} key={i}>
              <button className="page-link" onClick={() => handlePageChange(i)}>
                {i}
              </button>
            </li>
          );
        }
    
        return pageNumbers;
      };

    const exportStockPriceDetails = async () => {
        setIsLoading(true);
        const exportStockPriceDetailsResponse = await ExportTradingStockPrice(stockDate, tradingTickerSymbol)
        try {
            setIsLoading(false);
            if (exportStockPriceDetailsResponse && exportStockPriceDetailsResponse['status'] === 200) {
                const csvData = exportStockPriceDetailsResponse['data'];
                const blob = new Blob([csvData], { type: 'text/csv' });
                const url = URL.createObjectURL(blob);
                const anchor = document.createElement('a');

                anchor.href = url;
                anchor.download = tradingStocksExportFileName;

                document.body.appendChild(anchor);
                anchor.click();

                document.body.removeChild(anchor);
                URL.revokeObjectURL(url);

            } else if (exportStockPriceDetailsResponse['response'] && exportStockPriceDetailsResponse['response']['data']['client_status_code'] === 401) {
                let refreshToken = localStorage.getItem('refresh_token');
                const exportStockPriceAccessToken = await GetAccessToken(refreshToken);
                localStorage.setItem('access_token', exportStockPriceAccessToken['data']['data']['access_token']);
                exportStockPriceDetails();
            } else {
                setPopupRefusedMessage(VALIDATION_ALERT_MESSAGES.tryAgainAlert);
                setShowPopupRefused(true);
                setTimeout(() => {
                    setShowPopupRefused(false);
                }, 2000)
            }
        } catch (error) {
            setPopupRefusedMessage(VALIDATION_ALERT_MESSAGES.connectionRefusedAlert);
            setShowPopupRefused(true);
            setTimeout(() => {
                setShowPopupRefused(false);
            }, 2000);
        }
    }

    const handleSelectChange = () => {
        return;
    };

    return (
        <div className="app-container">
            <LeftNavbar onSelectChange={handleSelectChange} />
            <div className="main-container">
                <TopNavbar onSelectChange={handleSelectChange} />
                <Container fluid className="content">
                    <Row>
                        <Col md={11} className="dashboard">
                            <CardTitle tag="h1" className="ticker-profile-name">
                                Trading Stocks
                            </CardTitle>
                            <br />
                            <Card>
                                <CardHeader>
                                    <h5 className='calculation-title-size'>Search Date</h5>
                                </CardHeader>
                                <CardBody>
                                    <FormGroup>
                                        <Label for="stockDate">Date:</Label>
                                        <Input
                                            type="date"
                                            id="stockDate"
                                            name="stockDate"
                                            min="2004-01-01"
                                            max="2023-12-31"
                                            value={stockDate}
                                            onChange={(e) => setStockDate(e.target.value)}
                                            required
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="tradingTickerSymbol">Ticker Symbol:</Label>
                                        <Input
                                            type="text"
                                            id="tradingTickerSymbol"
                                            name="tradingTickerSymbol"
                                            value={tradingTickerSymbol}
                                            onChange={(e) => setTradingTickerSymbol(e.target.value)}
                                        />
                                        {errors.stockDate && (
                                            <div className="text-danger">{errors.stockDate}</div>
                                        )}
                                    </FormGroup>
                                    <div className="calculation-export-csv">
                                        <Button className="calculator-submit" type="button" onClick={allTradingStockPriceDetails}>
                                            <BsFillSaveFill className="table-icon" />
                                            Submit
                                        </Button>
                                    </div>
                                </CardBody>
                            </Card><br />
                            {tradingStockPriceDetails && (
                                <Card>
                                    <CardHeader className="card-header-result-table" >
                                        <div className="dashboard-search-option">
                                            <div className="d-flex justify-content-between mb-3">
                                                <div className="d-flex align-items-center">
                                                    <span className="ml-3">Show</span>
                                                    <select
                                                        id="pagination-entries"
                                                        name="entries"
                                                        value={itemsPerPage}
                                                        onChange={(e) => {
                                                            setItemsPerPage(Number(e.target.value));
                                                            setCurrentPage(1);
                                                        }}
                                                        className="form-control input-sm"
                                                    >
                                                        <option value="10">10</option>
                                                        <option value="25">25</option>
                                                        <option value="50">50</option>
                                                        <option value="100">100</option>
                                                    </select>
                                                    <span className="ml-2">entries</span>
                                                </div>
                                                <div className="search-export-csv">
                                                    <div>
                                                        <input
                                                            className="calculation-result-search"
                                                            type="text"
                                                            placeholder="Search"
                                                            value={searchTerm}
                                                            onChange={handleSearchChange}
                                                        />
                                                    </div>
                                                    {tradingStockPriceDetails && (
                                                        <div className="export-csv">
                                                            <Button className="tickers-result-export-csv" type="button" title="Export CSV" onClick={exportStockPriceDetails}>
                                                                <FaDownload className="table-icon" />
                                                                <span>Export</span>
                                                            </Button>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </CardHeader>
                                    <CardBody>
                                        <div className="table-responsive">
                                            <table className="table table-striped">
                                                <thead>
                                                    <tr className="ticker-records">
                                                        <th>Date</th>
                                                        <th>Ticker</th>
                                                        <th>Volume</th>
                                                        <th>Open</th>
                                                        <th>Close</th>
                                                        <th>High</th>
                                                        <th>Low</th>
                                                        <th>Num Trades</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {currentTickers.map((ticker) => (
                                                        <tr key={ticker.id} className="ticker-records">
                                                            <td>{ticker.date}</td>
                                                            <td>{ticker.ticker}</td>
                                                            <td>{ticker.volume}</td>
                                                            <td>{ticker.open}</td>
                                                            <td>{ticker.close}</td>
                                                            <td>{ticker.high}</td>
                                                            <td>{ticker.low}</td>
                                                            <td>{ticker.num_trades}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="d-flex justify-content-between mt-3">
                                            <div>
                                                Showing {indexOfFirstItem + 1} to {Math.min(indexOfLastItem, filteredTickers.length)} of{' '}
                                                {filteredTickers.length} entries
                                            </div>
                                            <nav aria-label="Page navigation example">
                                                <ul className="pagination justify-content-end">
                                                    <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                                        <button
                                                            className="page-link"
                                                            onClick={() => handlePageChange(currentPage - 1)}
                                                            disabled={currentPage === 1}
                                                        >
                                                            Previous
                                                        </button>
                                                    </li>
                                                    {renderPageNumbers()}
                                                    <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                                                        <button
                                                            className="page-link"
                                                            onClick={() => handlePageChange(currentPage + 1)}
                                                            disabled={currentPage === totalPages}
                                                        >
                                                            Next
                                                        </button>
                                                    </li>
                                                </ul>
                                            </nav>
                                        </div>
                                    </CardBody>
                                </Card>
                            )}<br />
                            {showPopupRefused && (
                                <RefusedPopup popupRefusedMessage={popupRefusedMessage} />
                            )}
                        </Col>
                    </Row>
                </Container>
                {isLoading && (
                    <LoadingScreen />
                )}
            </div>
        </div>
    );
}
