import React from 'react';
import { Card, CardBody } from 'reactstrap';


const DlomResultTables = ({ title, data }) => {

  return (
    <>
      <Card>
        <CardBody>
          <div className="table-responsive">
            <table className="table table-striped fixed-table">
              <thead>
                <tr>
                  <th className="calculation-result-table title-column">{title}</th>
                  <th className="calculation-records">Volatility Low</th>
                  <th className="calculation-records">Volatility Median</th>
                  <th className="calculation-records">Volatility High</th>
                </tr>
              </thead>
              <tbody>
                {data && Object.entries(data).map(([key, value]) => (
                  <tr key={key}>
                    <th className="title-column">{key}</th>
                    <td className="calculation-records">{value.volatility_low}</td>
                    <td className="calculation-records">{value.volatility_median}</td>
                    <td className="calculation-records">{value.volatility_high}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </CardBody>
      </Card>
      <br />
    </>
  );
};

export default DlomResultTables;
