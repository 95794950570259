import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserProfileDetails } from '../../ServerApi/server';
import { AiOutlineAppstore } from "react-icons/ai";
import { FaUser } from "react-icons/fa";
import { AiFillAppstore, AiOutlineStock } from "react-icons/ai";
import { GetAccessToken } from '../../ServerApi/server';
import { PiCalculatorLight } from "react-icons/pi";
import { LiaFileCodeSolid } from "react-icons/lia";
import { TbCircleKey } from "react-icons/tb";
import { StandardIndustrialCodeList } from '../../ServerApi/server';
import { VALIDATION_ALERT_MESSAGES } from '../../Helper/constants';
import SuccessPopup from './SuccessPopupMessage';
import RefusedPopup from './RefusedPopupMessage';


export default function LeftNavbar({ onSelectChange }) {
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState('');
  const [showPopupRefused, setShowPopupRefused] = useState(false);
  const [popupRefusedMessage, setPopupRefusedMessage] = useState('');
  const [isVisible, setIsVisible] = useState(false);
  const navigate = useNavigate();

  const dashboard = () => {
    navigate('/dashboard')
  }

  const userProfile = async () => {
    const userProfileResponse = await UserProfileDetails();
    try {
      if (userProfileResponse['data'] && userProfileResponse['data']['client_status_code'] === 200) {
        navigate('/user_profile', {
          state: {
            username: userProfileResponse['data']['username'],
            firstName: userProfileResponse['data']['firstname'],
            lastName: userProfileResponse['data']['lastname']
          }
        });
      } else if (userProfileResponse['data'] && userProfileResponse['data']['client_status_code'] === 400) {
        setPopupMessage(userProfileResponse['data']['message']);
        setShowPopup(true);
        setTimeout(() => {
          setShowPopup(false);
        }, 2000);
      } else if (userProfileResponse['response'] && userProfileResponse['response']['data']['client_status_code'] === 401) {
        let refreshToken = localStorage.getItem('refresh_token');
        const accessTokenResponse = await GetAccessToken(refreshToken);
        localStorage.setItem('access_token', accessTokenResponse['data']['data']['access_token']);
        userProfile()
      } else {
        setPopupRefusedMessage(VALIDATION_ALERT_MESSAGES.tryAgainAlert);
        setShowPopupRefused(true);
        setTimeout(() => {
          setShowPopupRefused(false);
        }, 2000)
      }
    } catch (error) {
      setPopupRefusedMessage(VALIDATION_ALERT_MESSAGES.connectionRefusedAlert);
      setShowPopupRefused(true);
      setTimeout(() => {
        setShowPopupRefused(false);
      }, 2000);
    }
  }

  const tickersData = () => {
    navigate('/tickers_data')
  }

  const tradingStocks = () => {
    navigate('/trading_stocks')
  }

  const apiKey = async () => {
    const userProfileResponse = await UserProfileDetails();
    try {
      if (userProfileResponse['data'] && userProfileResponse['data']['client_status_code'] === 200) {
        navigate('/user_api_key', {
          state: {
            userApiKey: userProfileResponse['data']['api_key'],
            status: userProfileResponse['data']['status']
          }
        });
      } else if (userProfileResponse['data'] && userProfileResponse['data']['client_status_code'] === 400) {
        setPopupMessage(userProfileResponse['data']['message']);
        setShowPopup(true);
        setTimeout(() => {
          setShowPopup(false);
        }, 2000);
      } else if (userProfileResponse['response'] && userProfileResponse['response']['data']['client_status_code'] === 401) {
        let refreshToken = localStorage.getItem('refresh_token');
        const accessTokenResponse = await GetAccessToken(refreshToken);
        localStorage.setItem('access_token', accessTokenResponse['data']['data']['access_token']);
        userProfile()
      } else {
        setPopupRefusedMessage(VALIDATION_ALERT_MESSAGES.tryAgainAlert);
        setShowPopupRefused(true);
        setTimeout(() => {
          setShowPopupRefused(false);
        }, 2000)
      }
    } catch (error) {
      setPopupRefusedMessage(VALIDATION_ALERT_MESSAGES.connectionRefusedAlert);
      setShowPopupRefused(true);
      setTimeout(() => {
        setShowPopupRefused(false);
      }, 2000);
    }
  }

  const standardIndustrialCode = async () => {
    const standardIndustrialCodeListResponse = await StandardIndustrialCodeList()
    try {
      if (standardIndustrialCodeListResponse['data'] && standardIndustrialCodeListResponse['data']['client_status_code']) {
        navigate('/standard_industrial_code', { state: { SCI_code: standardIndustrialCodeListResponse['data']['standard_industrial_code_list'] } })
      } else if (standardIndustrialCodeListResponse['response'] && standardIndustrialCodeListResponse['response']['data']['client_status_code'] === 401) {
        let refreshToken = localStorage.getItem('refresh_token');
        const accessTokenResponse = await GetAccessToken(refreshToken);
        localStorage.setItem('access_token', accessTokenResponse['data']['data']['access_token']);
        standardIndustrialCode()
      } else {
        setPopupRefusedMessage(VALIDATION_ALERT_MESSAGES.tryAgainAlert);
        setShowPopupRefused(true);
        setTimeout(() => {
          setShowPopupRefused(false);
        }, 2000)
      }
    } catch (error) {
      setPopupRefusedMessage(VALIDATION_ALERT_MESSAGES.connectionRefusedAlert);
      setShowPopupRefused(true);
      setTimeout(() => {
        setShowPopupRefused(false);
      }, 2000);
    }
  }

  return (
    <div className="left-navbar">
      <div className='search-export-csv'>
        <ul className="menu-text-color">
          <li><PiCalculatorLight className="calculator-icon" /></li>
        </ul>
        <ul>
          <li className="dlom-calculator">CALCULATOR</li>
        </ul>
      </div>
      <ul className='dashboard-menu-list'>
        <li className="navbar-text-color">
          <span className="navbar-text-span" title="Dashboard" onClick={dashboard}>
            <AiOutlineAppstore className="icon" />
            Dashboard
          </span>
        </li>
        <li className="navbar-text-color">
          <span className="navbar-text-span" title="My Profile" onClick={userProfile}>
            <FaUser className="icon" />
            My Profile
          </span>
        </li>
        <li className="navbar-text-color">
          <span className="navbar-text-span" title="Tickers" onClick={tickersData}>
            <AiFillAppstore className="icon" />
            Tickers
          </span>
        </li>
        {isVisible && (
          <li className="navbar-text-color">
            <span className="navbar-text-span" title="Trading Stocks" onClick={tradingStocks}>
              <AiOutlineStock className="icon" />
              Trading Stocks
            </span>
          </li>
        )}
        <li className="navbar-text-color">
          <span className="navbar-text-span" title="SIC Codes" onClick={standardIndustrialCode}>
            <LiaFileCodeSolid className="icon" />
            SIC Codes
          </span>
        </li>
        <li className="navbar-text-color">
          <span className="navbar-text-span" title="Api Key" onClick={apiKey}>
          <TbCircleKey className="icon"/>
            API Key
          </span>
        </li>
      </ul>
      {showPopup && (
        <SuccessPopup popupMessage={popupMessage} />
      )}
      {showPopupRefused && (
        <RefusedPopup popupRefusedMessage={popupRefusedMessage} />
      )}
    </div>
  );
};
