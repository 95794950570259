export const VALIDATION_ALERT_MESSAGES = {
    'yearMonthValidator': 'Valuation Year and Month is required.',
    'invalidDateAlert': 'Please select the valid date.',
    'blockSizeValidator': 'Block Size is required.',
    'invalidBlockSizeAlert': 'Please enter the block size as a decimal number or percentage (e.g., 1.25 or 1.25%).',
    'marketValueValidator': 'Market Value is required.',
    'sicCodeValidator': 'The two digits SIC Code is required.',
    'sicCodeAlert': 'SIC Code must be two digits.',
    'tickerYearValidator': 'Valuation Year and Month is required.',
    'tickerSymbolValidator': 'You must enter the ticker symbol.',
    'tradingDateValidator': 'You must enter either date or ticker symbol.',
    'tryAgainAlert': 'Please try again.',
    'connectionRefusedAlert': 'Connection refused.',
    'invalidDateFormat': 'Enter the correct format (YYYY-MM).',
    'invalidTickerSymbol': "Enter one or more ticker symbols, separated by commas (',').",
    'portfolioSizeAlert': 'Note: Small number of observations, please exercise caution in interpreting the results.',
    'dlomCalculationAlert': 'Six-month Reg 144 Holding period added to liquidation period to estimate discount for lack of marketability.',
    'sicCodeTooltipAlert': 'Please enter a 2-digit SIC code.',
    'apiKeyStatus': 'XXXXXXXXXXXXXXX',
    'apiKeyActiveStatus': 'Active',
    'apiKeyBlockedStatus': 'Blocked'
}