import React, { useState, useEffect } from 'react';
import { LoginAPI } from '../../ServerApi/server'
import { useNavigate } from 'react-router-dom';
import { IoMailSharp } from "react-icons/io5";
import { FaLock } from "react-icons/fa6";
import { VALIDATION_ALERT_MESSAGES } from '../../Helper/constants';
import SuccessPopup from '../Dlom/SuccessPopupMessage';
import RefusedPopup from '../Dlom/RefusedPopupMessage';


export default function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    const [showPopup, setShowPopup] = useState(false);
    const [popupMessage, setPopupMessage] = useState('');
    const [showPopupRefused, setShowPopupRefused] = useState(false);
    const [popupRefusedMessage, setPopupRefusedMessage] = useState('');

    useEffect(() => {
        const component = async () => {
            localStorage.removeItem('access_token');
            localStorage.removeItem('refresh_token');
            localStorage.removeItem('user_id');
        }
        component();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const userLoginApiResponse = await LoginAPI(email, password);
            if (userLoginApiResponse['data']['client_status_code'] === 200) {
                localStorage.setItem('access_token', userLoginApiResponse['data']['data']['access_token']);
                localStorage.setItem('refresh_token', userLoginApiResponse['data']['data']['refresh_token']);
                localStorage.setItem('user_id', userLoginApiResponse['data']['data']['user_id']);
                setPopupMessage(userLoginApiResponse['data']['message']);
                setShowPopup(true);
                setTimeout(() => {
                    setShowPopup(false);
                    navigate('/dashboard');
                }, 2000);
            } else {
                setPopupMessage(userLoginApiResponse['data']['message']);
                setShowPopup(true);
                setTimeout(() => {
                    setShowPopup(false);
                }, 2000);
            }
        } catch (error) {
            setPopupRefusedMessage(VALIDATION_ALERT_MESSAGES.connectionRefusedAlert);
            setShowPopupRefused(true);
            setTimeout(() => {
                setShowPopupRefused(false);
            }, 2000);
        }
    }

    const signPage = () => {
        navigate('/sign_up')
    }

    const forgetPasswordPage = () => {
        navigate('/forgot_password')
    }

    return (
        <div className="app">
            <div className='login-app'>
                < div className="auth-form-container">
                    <div className='login-btn'>
                        Login
                    </div>
                    <form className="login-form" onSubmit={handleSubmit}>
                        <div className="login-parent-inputs">
                            <div className="login-username-input">
                                <IoMailSharp className="username-icon" />
                                <input className="input" value={email} onChange={(e) => setEmail(e.target.value)} type="email" placeholder='Email' required />
                            </div>
                            <div className='login-password-input'>
                                <FaLock className="username-icon" />
                                <input className="input" value={password} onChange={(e) => setPassword(e.target.value)} type="password" placeholder='Password' required />
                            </div>
                            <button className="login-button" type='submit'>Submit</button>
                        </div>
                    </form>
                    <span className="link-btn">Don't have an account? <a className='signUp-button' onClick={signPage}>Signup</a></span>
                    <span className="forget-btn"><a className='forget-password-button' onClick={forgetPasswordPage}>Forgot Password?</a></span>
                    {showPopup && (
                        <SuccessPopup popupMessage={popupMessage} />
                    )}
                    {showPopupRefused && (
                        <RefusedPopup popupRefusedMessage={popupRefusedMessage} />
                    )}
                </div>
            </div>
        </div>
    )
}
