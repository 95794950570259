import React from 'react'


export default function LoadingScreen() {
    return (
        <div className="loading-screen">
            <div className="loading-wave">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    )
}