import React, { useRef } from 'react';
import { Container, Row, Col, Card, CardBody, CardTitle, Table, CardHeader, Button } from 'reactstrap';
import TopNavbar from './TopNavbar';
import LeftNavbar from './LeftNavbar';
import { MdOutlineContentCopy } from "react-icons/md";
import { VALIDATION_ALERT_MESSAGES } from '../../Helper/constants';
import { useLocation } from 'react-router-dom';


export default function UserApiKey() {
    const apiRef = useRef(null);
    const { state: user_details } = useLocation();
    let { userApiKey, status } = user_details;
    let apiKeyStatus = VALIDATION_ALERT_MESSAGES.apiKeyActiveStatus

    if (status != 0) {
        userApiKey = VALIDATION_ALERT_MESSAGES.apiKeyStatus
        apiKeyStatus = VALIDATION_ALERT_MESSAGES.apiKeyBlockedStatus
    }

    const handleCopyClick = async () => {
        await navigator.clipboard.writeText(userApiKey);
        const icon = document.querySelector('.apiKey-button');
        icon.style.backgroundColor = '#18171778';

        setTimeout(() => {
            icon.style.backgroundColor = '';
        }, 2000);
    };

    const handleSelectChange = () => {
        return;
    };


    return (
        <div className="app-container">
            <LeftNavbar onSelectChange={handleSelectChange}/>
            <div className="main-container">
                <TopNavbar onSelectChange={handleSelectChange}/>
                <Container fluid className="content">
                    <Row>
                        <Col md={11} className="dashboard">
                            <CardTitle tag="h1" className='user-profile-name'>User API Key</CardTitle>
                            <br />
                            <Card>
                                <CardHeader>
                                    <h5 className='calculation-title-size'>Api Key</h5>
                                </CardHeader>
                                <CardBody className='apiKey-cardbody'>
                                    <Table striped className='apiKey-table'>
                                        <tbody>
                                            <tr>
                                                <th><span className='apiKey-header'>API Key</span></th>
                                                <td>
                                                    <input
                                                        type="text"
                                                        ref={apiRef}
                                                        value={userApiKey}
                                                        readOnly
                                                        className='apiKey-input-field'
                                                    />
                                                </td>
                                                <td>
                                                    {status === 0 ? (
                                                        <MdOutlineContentCopy color="primary" className='apiKey-button' title="copy" onClick={handleCopyClick} />
                                                    ) : ( <MdOutlineContentCopy color="primary" className='apiKey-blocked-button'/>)}
                                                </td>
                                                <td>
                                                   <span className="apiKey-header">{apiKeyStatus}</span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
};




