import React from 'react';
import { Card, CardBody } from 'reactstrap';
import { VALIDATION_ALERT_MESSAGES } from '../../Helper/constants';

const DlomLiquidationPeriodTable = ({ size, title, data }) => {
  return (
    <>
      <Card>
        <CardBody>
          <div className="table-responsive">
            <table className="table table-striped ">
              <thead>
                <tr>
                  <th className="calculation-result-table liquidation-period">{title}</th>
                  <th className="calculation-volatility-records">Volatility (Annualized Standard Deviation %)</th>
                  <th className="calculation-volatility-records">Liquidation Period(days) with no transfer restrictions</th>
                </tr>
              </thead>
              <tbody>
                {data && Object.entries(data).map(([key, value]) => (
                  <tr key={key}>
                    <th className="calculation-records">{key}</th>
                    <td className="calculation-records">{value.volatility}</td>
                    <td className="calculation-records">{value.liquidation_period}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            {size && size <= 10 && (
              <span className="dlom-alert-observation"><b>{VALIDATION_ALERT_MESSAGES.portfolioSizeAlert}</b><br /></span>
            )}
          </div>
        </CardBody>
      </Card>
      <br />
    </>
  );
};

export default DlomLiquidationPeriodTable;
