import React, { useState } from 'react'
import { SignUpAPI } from '../../ServerApi/server'
import { useNavigate } from 'react-router-dom';
import { FaUser } from "react-icons/fa";
import { IoMailSharp } from "react-icons/io5";
import { FaLock } from "react-icons/fa6";
import { VALIDATION_ALERT_MESSAGES } from '../../Helper/constants';
import SuccessPopup from '../Dlom/SuccessPopupMessage';
import RefusedPopup from '../Dlom/RefusedPopupMessage';


export default function SignUp() {
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('');
    const [pass, setPass] = useState('');
    const [confirm, setConfirm] = useState('');
    const navigate = useNavigate();
    const [showPopup, setShowPopup] = useState(false);
    const [popupMessage, setPopupMessage] = useState('');
    const [showPopupRefused, setShowPopupRefused] = useState(false);
    const [popupRefusedMessage, setPopupRefusedMessage] = useState('');

    const SignUpNewUser = async (e) => {
        e.preventDefault();
        try {
            if (!email || !pass || !confirm || !firstName || !lastName) {
                return;
            }
            const signUpApiResponse = await SignUpAPI(email, pass, confirm, firstName, lastName);
            if (signUpApiResponse['data']['client_status_code'] === 200) {
                localStorage.removeItem('access_token');
                localStorage.removeItem('refresh_token');
                localStorage.removeItem('user_id');
                setPopupMessage(signUpApiResponse['data']['message']);
                setShowPopup(true);
                setTimeout(() => {
                    setShowPopup(false);
                    navigate('/')
                }, 2000);
            } else {
                setPopupMessage(signUpApiResponse['data']['message']);
                setShowPopup(true);
                setTimeout(() => {
                    setShowPopup(false);
                }, 2000);
            }
        } catch (error) {
            setPopupRefusedMessage(VALIDATION_ALERT_MESSAGES.connectionRefusedAlert);
            setShowPopupRefused(true);
            setTimeout(() => {
                setShowPopupRefused(false);
            }, 2000);
        }
    }

    const loginPage = () => {
        navigate('/')
    }

    return (
        <div className="app">
            <div className='signUp-app'>
                <div className="auth-form-container">
                    <div className='signUp-btn'>
                        Signup
                    </div>
                    <form className="signup-form" onSubmit={SignUpNewUser}>
                        <div className="login-parent-inputs">
                            <div className='signUp-input-details'>
                                <FaUser className="username-icon" />
                                <input className="signUp-input" value={firstName} onChange={(e) => setFirstName(e.target.value)} type="text" placeholder='First Name' required />
                            </div>
                            <div className='signUp-input-details'>
                                <FaUser className="username-icon" />
                                <input className="signUp-input" value={lastName} onChange={(e) => setLastName(e.target.value)} type="text" placeholder='Last Name' required />
                            </div>
                            <div className='signUp-input-details'>
                                <IoMailSharp className="username-icon" />
                                <input className="signUp-input" value={email} onChange={(e) => setEmail(e.target.value)} type="email" placeholder='Email' required />
                            </div>
                            <div className='signUp-input-details'>
                                <FaLock className="username-icon" />
                                <input className="signUp-input" value={pass} onChange={(e) => setPass(e.target.value)} type="password" placeholder='Password' required />
                            </div>
                            <div className='signUp-input-details'>
                                <FaLock className="username-icon" />
                                <input className="signUp-input" value={confirm} onChange={(e) => setConfirm(e.target.value)} type="password" placeholder='Confirm Password' required />
                            </div>
                            <button className="login-button" type="submit">Submit</button>
                        </div>
                    </form>
                    <span className="link-btn">Already have an account? <a className='signUp-button' onClick={loginPage}>Login</a></span>
                    {showPopup && (
                        <SuccessPopup popupMessage={popupMessage} />
                    )}
                    {showPopupRefused && (
                        <RefusedPopup popupRefusedMessage={popupRefusedMessage} />
                    )}
                </div>
            </div>
        </div>
    )
}
