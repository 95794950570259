import React, { useState } from 'react';
import TopNavbar from './TopNavbar';
import LeftNavbar from './LeftNavbar';
import { TickerDetails } from '../../ServerApi/server';
import { GetAccessToken } from '../../ServerApi/server';
import { BsFillSaveFill } from "react-icons/bs";
import { FaDownload } from 'react-icons/fa';
import { ExportTickerDetails } from '../../ServerApi/server';
import RefusedPopup from './RefusedPopupMessage';
import LoadingScreen from './LoadingScreen';
import DatePicker from 'react-datepicker';
import { parseISO } from 'date-fns';
import 'react-datepicker/dist/react-datepicker.css';
import { Container, Col, CardTitle, Card, CardHeader, CardBody, Row, FormGroup, Label, Input, Button, Tooltip } from 'reactstrap';
import { VALIDATION_ALERT_MESSAGES } from '../../Helper/constants';
import { tickerDetailsExportFileName, maxDate, minDate } from '../../Helper/helpers';


export default function TickersList() {
    const [tickerYear, setTickerYear] = useState('');
    const [tickerSymbol, setTickerSymbol] = useState([]);
    const [tickersDetails, setTickersDetails] = useState(null);
    const [dlomOriginalValues, setDlomOriginalValues] = useState('');
    const [showPopupRefused, setShowPopupRefused] = useState(false);
    const [popupRefusedMessage, setPopupRefusedMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [blockSize, setBlockSize] = useState('')
    const [blockSizeTooltip, setBlockSizeTooltip] = useState(false);
    const toggleBlockSizeTooltip = () => setBlockSizeTooltip(!blockSizeTooltip);
    const [tickerSymbolTooltip, setTickerSymbolTooltip] = useState(false);
    const toggleTickerSymbolTooltip = () => setTickerSymbolTooltip(!tickerSymbolTooltip);
    const [showCalendar, setShowCalendar] = useState(false);
    const min = new Date('2005-01-15');
    const max = new Date('2022-12-15');

    const handleDateChange = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const yearMonthDate = `${year}-${month}`;

        setTickerYear(yearMonthDate);
        setShowCalendar(false);
    };

    const handleClickOutside = () => {
        setShowCalendar(false);
    };

    const [errors, setErrors] = useState({
        tickerYear: ''
    });

    const tickerValidate = () => {
        let valid = true;
        const tickerDetails = {
            tickerYear: '',
            tickerSymbol: '',
            blockSize: ''
        };

        const isValidFormat = /^[0-9]{4}-[0-9]{2}$/.test(tickerYear);
        const invalidCharsRegex = /[^0-9.%]/;

        if (!isValidFormat) {
            setTickerYear('');
            tickerDetails.tickerYear = VALIDATION_ALERT_MESSAGES.invalidDateFormat;
            valid = false;
        }

        if (!tickerYear) {
            tickerDetails.tickerYear = VALIDATION_ALERT_MESSAGES.tickerYearValidator;
            valid = false;
        }

        if (tickerYear && (tickerYear < minDate || tickerYear > maxDate)) {
            setTickerYear('');
            tickerDetails.tickerYear = VALIDATION_ALERT_MESSAGES.invalidDateAlert;
            valid = false;
        }

        if (!tickerSymbol || tickerSymbol.length === 0) {
            tickerDetails.tickerSymbol = VALIDATION_ALERT_MESSAGES.tickerSymbolValidator;
            valid = false;
        }

        const containsSpecialCharacters = tickerSymbol.some(symbol => /[;:"',\-_\/]/.test(symbol));

        if (containsSpecialCharacters) {
            tickerDetails.tickerSymbol = VALIDATION_ALERT_MESSAGES.INVALID_TICKER_SYMBOL;
            valid = false;
        }

        if (!blockSize) {
            tickerDetails.blockSize = VALIDATION_ALERT_MESSAGES.blockSizeValidator;
            valid = false;
        } else if (!/^\d+(\.\d+)?%?$/.test(blockSize) || invalidCharsRegex.test(blockSize)) {
            tickerDetails.blockSize = VALIDATION_ALERT_MESSAGES.invalidBlockSizeAlert;
            valid = false;
        }

        setErrors(tickerDetails);
        return valid;
    }

    const getTickerDetails = async () => {
        if (!tickerValidate()) {
            return;
        }
        setIsLoading(true);
        const tickerDetailResponse = await TickerDetails(tickerYear, tickerSymbol, blockSize);

        try {
            setIsLoading(false);
            if (tickerDetailResponse['data'] && tickerDetailResponse['data']['client_status_code'] === 200) {
                setTickersDetails(tickerDetailResponse['data']['dlom_ticker_calculation_result']);
                setDlomOriginalValues(tickerDetailResponse['data']['dlom_tickers_original_details']);
            } else if (tickerDetailResponse['data'] && tickerDetailResponse['data']['client_status_code'] === 400) {
                setTickersDetails('')
                setDlomOriginalValues('')
                setPopupRefusedMessage(tickerDetailResponse['data']['message']);
                setShowPopupRefused(true);
                setTimeout(() => {
                    setShowPopupRefused(false);
                }, 2000)
            } else if (tickerDetailResponse['response'] && tickerDetailResponse['response']['data']['client_status_code'] === 401) {
                let refreshToken = localStorage.getItem('refresh_token');
                const accessTokenResponse = await GetAccessToken(refreshToken);
                localStorage.setItem('access_token', accessTokenResponse['data']['data']['access_token']);
                getTickerDetails();
            } else {
                setPopupRefusedMessage(VALIDATION_ALERT_MESSAGES.tryAgainAlert);
                setShowPopupRefused(true);
                setTimeout(() => {
                    setShowPopupRefused(false);
                }, 2000)
            }
        } catch (error) {
            setPopupRefusedMessage(VALIDATION_ALERT_MESSAGES.connectionRefusedAlert);
            setShowPopupRefused(true);
            setTimeout(() => {
                setShowPopupRefused(false);
            }, 2000);
        }
    };

    const exportDetails = async () => {
        setIsLoading(true);
        const exportTickerDetailsResponse = await ExportTickerDetails(tickerYear, tickerSymbol, blockSize)
        try {
            setIsLoading(false);
            if (exportTickerDetailsResponse && exportTickerDetailsResponse['status'] === 200) {
                const csvData = exportTickerDetailsResponse['data'];
                const blob = new Blob([csvData], { type: 'text/csv' });
                const url = URL.createObjectURL(blob);
                const anchor = document.createElement('a');

                anchor.href = url;
                anchor.download = tickerDetailsExportFileName;

                document.body.appendChild(anchor);
                anchor.click();

                document.body.removeChild(anchor);
                URL.revokeObjectURL(url);
            } else if (exportTickerDetailsResponse['response'] && exportTickerDetailsResponse['response']['data']['client_status_code'] === 401) {
                let refreshToken = localStorage.getItem('refresh_token');
                const exportTickerResponse = await GetAccessToken(refreshToken);
                localStorage.setItem('access_token', exportTickerResponse['data']['data']['access_token']);
                exportDetails();
            } else {
                setPopupRefusedMessage(VALIDATION_ALERT_MESSAGES.tryAgainAlert);
                setShowPopupRefused(true);
                setTimeout(() => {
                    setShowPopupRefused(false);
                }, 2000)
            }
        } catch (error) {
            setPopupRefusedMessage(VALIDATION_ALERT_MESSAGES.connectionRefusedAlert);
            setShowPopupRefused(true);
            setTimeout(() => {
                setShowPopupRefused(false);
            }, 2000);
        }
    }

    const handleSelectChange = () => {
        return;
    };

    const handleTickerSymbolChange = (e) => {
        const inputText = e.target.value;
        const isBackspace = e.nativeEvent.inputType === 'deleteContentBackward';

        if (isBackspace) {
            setTickerSymbol((prevSymbols) => {
                const lastSymbol = prevSymbols[prevSymbols.length - 1];
                const updatedLastSymbol = lastSymbol.slice(0, -1);
                const updatedSymbols = [...prevSymbols.slice(0, -1), updatedLastSymbol].filter(Boolean);
                return updatedSymbols;
            });
        } else {
            const symbolsArray = inputText.split(',').map((symbol) => symbol.trim());
            setTickerSymbol(symbolsArray);
        }
    };


    return (
        <div className="app-container">
            <LeftNavbar onSelectChange={handleSelectChange} />
            <div className="main-container">
                <TopNavbar onSelectChange={handleSelectChange} />
                <Container fluid className="content">
                    <Row>
                        <Col md={11} className="dashboard">
                            <CardTitle tag="h1" className="ticker-profile-name">
                                Tickers
                            </CardTitle>
                            <br />
                            <Card>
                                <CardHeader>
                                    <div className="calculation-output-csv">
                                        <h5 className='calculation-title-size'>Search Tickers </h5>
                                        <div className="search-export-csv">
                                            {tickersDetails && (
                                                <div className="export-csv">
                                                    <Button className="tickers-result-export-csv" type="button" title="Export CSV" onClick={exportDetails}>
                                                        <FaDownload className="table-icon" />
                                                        <span>Export</span>
                                                    </Button>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </CardHeader>
                                <CardBody>
                                    <FormGroup>
                                        <Label for="tickerYear">Year and Month:</Label>
                                        <Input
                                            type="text"
                                            placeholder="YYYY-MM"
                                            value={tickerYear}
                                            onFocus={() => setShowCalendar(true)}
                                            onChange={(e) => setTickerYear(e.target.value)}
                                            required
                                        />
                                        {showCalendar && (
                                            <div className="year-month-calendar">
                                                <DatePicker
                                                    selected={tickerYear ? parseISO(tickerYear) : null}
                                                    onChange={handleDateChange}
                                                    minDate={min}
                                                    maxDate={max}
                                                    dateFormat="yyyy-MM"
                                                    showMonthYearPicker
                                                    inline
                                                    onClickOutside={handleClickOutside}
                                                    className="custom-datepicker"
                                                />
                                            </div>
                                        )}
                                        {errors.tickerYear && (
                                            <div className="text-danger">{errors.tickerYear}</div>
                                        )}
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="tickerSymbol">Ticker Symbol:</Label>
                                        <Input
                                            type="text"
                                            id="tickerSymbol"
                                            name="tickerSymbol"
                                            value={tickerSymbol.join(', ')}
                                            onChange={handleTickerSymbolChange}

                                        />
                                        {errors.tickerSymbol && (
                                            <div className="text-danger">{errors.tickerSymbol}</div>
                                        )}
                                        <Tooltip
                                            placement="top"
                                            isOpen={tickerSymbolTooltip}
                                            autohide={false}
                                            target="tickerSymbol"
                                            toggle={toggleTickerSymbolTooltip}
                                        >
                                            {VALIDATION_ALERT_MESSAGES.invalidTickerSymbol}
                                        </Tooltip>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="blockSize">Block Size:</Label>
                                        <Input
                                            type="text"
                                            id="blockSize"
                                            name="blockSize"
                                            value={blockSize}
                                            onChange={(e) => setBlockSize(e.target.value)}
                                            required
                                        />
                                        {errors.blockSize && (
                                            <div className="text-danger">{errors.blockSize}</div>
                                        )}
                                        <Tooltip
                                            placement="top"
                                            isOpen={blockSizeTooltip}
                                            autohide={false}
                                            target="blockSize"
                                            toggle={toggleBlockSizeTooltip}
                                        >
                                            {VALIDATION_ALERT_MESSAGES.invalidBlockSizeAlert}
                                        </Tooltip>
                                    </FormGroup>
                                    <div className="calculation-export-csv">
                                        <Button className="calculator-submit" type="button" title="Submit" onClick={getTickerDetails}>
                                            <BsFillSaveFill className="table-icon" />
                                            Submit
                                        </Button>
                                    </div>
                                </CardBody>
                            </Card><br />
                            {dlomOriginalValues && (
                                <div>
                                    <Card>
                                        <CardHeader>Original Description Details</CardHeader>
                                        <CardBody>
                                            <div className="table-responsive">
                                                <table className="table table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th className="liquidation-result-table">Ticker</th>
                                                            <th className="liquidation-annualized-value">Annualized Standard Deviation</th>
                                                            <th className="liquidation-result-table">Half Life</th>
                                                            <th className="liquidation-result-table">Sic</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {dlomOriginalValues.map((item, index) => (
                                                            <tr key={index}>
                                                                <td className="liquidation-records">{item.ticker}</td>
                                                                <td className="liquidation-records">{item.annualized_standard_deviation}</td>
                                                                <td className="liquidation-records">{item.half_life}</td>
                                                                <td className="liquidation-records">{item.sic}</td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </CardBody>
                                    </Card>
                                    <br />
                                </div>
                            )}
                            {tickersDetails && tickersDetails.map((item, index) => (
                                <div key={index}>
                                    <Card>
                                        <CardHeader>Discount ({item.ticker})</CardHeader>
                                        <CardBody>
                                            <div className="table-responsive">
                                                <table className="table table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th className="calculation-result-table liquidation-period">Model</th>
                                                            <th className="calculation-volatility-records">Blockage</th>
                                                            <th className="calculation-volatility-records">Dlom</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {Object.entries(item).map(([field, value]) => (
                                                            field !== 'ticker' && (
                                                                <tr key={field}>
                                                                    <th className="calculation-records">{field}</th>
                                                                    {Array.isArray(value) ? (
                                                                        value.map((subValue, subIndex) => (
                                                                            <td key={subIndex} className="calculation-records">{subValue}</td>
                                                                        ))
                                                                    ) : (
                                                                        <td className="calculation-records">{value}</td>
                                                                    )}
                                                                </tr>
                                                            )
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </CardBody>
                                    </Card>
                                    <br />
                                </div>
                            ))}
                            {showPopupRefused && (
                                <RefusedPopup popupRefusedMessage={popupRefusedMessage} />
                            )}
                        </Col>
                    </Row>
                </Container>
                {isLoading && (
                    <LoadingScreen />
                )}
            </div>
        </div>
    );
};

