import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { GetAccessToken } from '../../ServerApi/server';
import { AiOutlinePoweroff } from "react-icons/ai";
import { IoMdMenu } from "react-icons/io";
import { UserProfileDetails } from '../../ServerApi/server'
import { StandardIndustrialCodeList } from '../../ServerApi/server';
import { FormGroup, Label, Input } from 'reactstrap';
import { VALIDATION_ALERT_MESSAGES } from '../../Helper/constants';
import SuccessPopup from './SuccessPopupMessage';
import RefusedPopup from './RefusedPopupMessage';


export default function TopNavbar({ onSelectChange }) {
    const dropdownRef = useRef(null);
    const [showPopupRefused, setShowPopupRefused] = useState(false);
    const [popupRefusedMessage, setPopupRefusedMessage] = useState('');
    const navigate = useNavigate();
    const [popupMessage, setPopupMessage] = useState('');
    const [showPopup, setShowPopup] = useState(false);
    const [blockageToggle, setBlockageToggle] = useState(true);
    const [dlomToggle, setDlomToggle] = useState(false);
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const menuDropdown = useRef(null);
    const [isVisible, setIsVisible] = useState(false);

    const userProfile = async () => {
        setDropdownOpen(false);
        const userProfileResponse = await UserProfileDetails();
        try {
            if (userProfileResponse['data'] && userProfileResponse['data']['client_status_code'] === 200) {
                navigate('/user_profile', {
                    state: {
                        username: userProfileResponse['data']['username'],
                        firstName: userProfileResponse['data']['firstname'],
                        lastName: userProfileResponse['data']['lastname']
                    }
                });
            } else if (userProfileResponse['data'] && userProfileResponse['data']['client_status_code'] === 400) {
                setPopupMessage(userProfileResponse['data']['message']);
                setShowPopup(true);
                setTimeout(() => {
                    setShowPopup(false);
                }, 2000);
            } else if (userProfileResponse['response'] && userProfileResponse['response']['data']['client_status_code'] === 401) {
                let refreshToken = localStorage.getItem('refresh_token');
                const accessTokenResponse = await GetAccessToken(refreshToken);
                localStorage.setItem('access_token', accessTokenResponse['data']['data']['access_token']);
                userProfile()
            } else {
                setPopupRefusedMessage(VALIDATION_ALERT_MESSAGES.tryAgainAlert);
                setShowPopupRefused(true);
                setTimeout(() => {
                    setShowPopupRefused(false);
                }, 2000)
            }
        } catch (error) {
            setPopupRefusedMessage(VALIDATION_ALERT_MESSAGES.connectionRefusedAlert);
            setShowPopupRefused(true);
            setTimeout(() => {
                setShowPopupRefused(false);
            }, 2000);
        }
    }

    const Logout = async () => {
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        localStorage.removeItem('user_id');
        window.location.pathname = '/'
    }

    const standardIndustrialCode = async () => {
        setDropdownOpen(false);
        const standardIndustrialCodeListResponse = await StandardIndustrialCodeList()
        try {
            if (standardIndustrialCodeListResponse['data'] && standardIndustrialCodeListResponse['data']['client_status_code']) {
                navigate('/standard_industrial_code', { state: { SCI_code: standardIndustrialCodeListResponse['data']['standard_industrial_code_list'] } })
            } else if (standardIndustrialCodeListResponse['response'] && standardIndustrialCodeListResponse['response']['data']['client_status_code'] === 401) {
                let refreshToken = localStorage.getItem('refresh_token');
                const accessTokenResponse = await GetAccessToken(refreshToken);
                localStorage.setItem('access_token', accessTokenResponse['data']['data']['access_token']);
                standardIndustrialCode()
            } else {
                setPopupRefusedMessage(VALIDATION_ALERT_MESSAGES.tryAgainAlert);
                setShowPopupRefused(true);
                setTimeout(() => {
                    setShowPopupRefused(false);
                }, 2000)
            }
        } catch (error) {
            setPopupRefusedMessage(VALIDATION_ALERT_MESSAGES.connectionRefusedAlert);
            setShowPopupRefused(true);
            setTimeout(() => {
                setShowPopupRefused(false);
            }, 2000);
        }

    }

    const toggleDropdown = () => {
        setDropdownOpen(!isDropdownOpen);
    };

    const handleClickOutside = (event) => {
        if (menuDropdown.current && !menuDropdown.current.contains(event.target)) {
            setDropdownOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    const dashboard = () => {
        setDropdownOpen(false);
        navigate('/dashboard')
    };

    const tickersData = () => {
        setDropdownOpen(false);
        navigate('/tickers_data')
    };

    const tradingStocks = () => {
        setDropdownOpen(false);
        navigate('/trading_stocks')
    };

    const handleBlockageToggle = () => {
        setBlockageToggle(true);
        setDlomToggle(false);
        onSelectChange(true)
    };

    const handleDlomToggle = () => {
        setDlomToggle(true);
        setBlockageToggle(false);
        onSelectChange(false)
    };

    const apiKey = async () => {
        setDropdownOpen(false);
        const userProfileResponse = await UserProfileDetails();
        try {
            if (userProfileResponse['data'] && userProfileResponse['data']['client_status_code'] === 200) {
                navigate('/user_api_key', {
                    state: {
                        userApiKey: userProfileResponse['data']['api_key'],
                        status: userProfileResponse['data']['status']
                    }
                });
            } else if (userProfileResponse['data'] && userProfileResponse['data']['client_status_code'] === 400) {
                setPopupMessage(userProfileResponse['data']['message']);
                setShowPopup(true);
                setTimeout(() => {
                    setShowPopup(false);
                }, 2000);
            } else if (userProfileResponse['response'] && userProfileResponse['response']['data']['client_status_code'] === 401) {
                let refreshToken = localStorage.getItem('refresh_token');
                const accessTokenResponse = await GetAccessToken(refreshToken);
                localStorage.setItem('access_token', accessTokenResponse['data']['data']['access_token']);
                userProfile()
            } else {
                setPopupRefusedMessage(VALIDATION_ALERT_MESSAGES.tryAgainAlert);
                setShowPopupRefused(true);
                setTimeout(() => {
                    setShowPopupRefused(false);
                }, 2000)
            }
        } catch (error) {
            setPopupRefusedMessage(VALIDATION_ALERT_MESSAGES.connectionRefusedAlert);
            setShowPopupRefused(true);
            setTimeout(() => {
                setShowPopupRefused(false);
            }, 2000);
        }
    };


    return (
        <div className="top-navbar">
            <div className="profile-icon">
                <div className='blockage-toggle-options'>
                    <IoMdMenu className="dashboard-menu-options" onClick={toggleDropdown} />
                    <div className='top-navbar-blockage'>
                        <FormGroup switch className="blockage-toggle">
                            <Label check className="user-logout">
                                <Input
                                    className="user-logout"
                                    type="switch"
                                    role="switch"
                                    id="blockageSwitch"
                                    checked={blockageToggle}
                                    onChange={handleBlockageToggle}
                                    title="BLOCKAGE"
                                />
                                BLOCKAGE
                            </Label>
                        </FormGroup>
                        <FormGroup switch className='dlom-toggle'>
                            <Label check className="user-logout">
                                <Input
                                    className="user-logout"
                                    type="switch"
                                    role="switch"
                                    id="dlomSwitch"
                                    checked={dlomToggle}
                                    onChange={handleDlomToggle}
                                    title="DLOM"
                                />
                                DLOM
                            </Label>
                        </FormGroup>
                    </div>
                </div>
                <div>
                    <h1 className='profile-options'>
                        <span className="user-logout" onClick={Logout} title="Logout">
                            <AiOutlinePoweroff className="logout-icon" />
                            Logout
                        </span>
                    </h1>
                </div>
            </div>
            <div>
                {isDropdownOpen && (
                    <div className="dropdown" ref={dropdownRef}>
                        <ul>
                            <li onClick={dashboard}>Dashboard</li>
                            <li onClick={userProfile}>My Profile</li>
                            <li onClick={tickersData}>Tickers</li>
                            {isVisible && (
                                <li onClick={tradingStocks}>Trading Stocks</li>
                            )}
                            <li onClick={standardIndustrialCode}>SIC Codes</li>
                            <li onClick={apiKey}>API Key</li>
                        </ul>
                    </div>
                )}
            </div>
            {showPopupRefused && (
                <RefusedPopup popupRefusedMessage={popupRefusedMessage} />
            )}
            {showPopup && (
                <SuccessPopup popupMessage={popupMessage} />
            )}
        </div>
    );
};

