import React, { useState } from 'react'
import { UserNewPassword } from '../../ServerApi/server'
import { useNavigate, useLocation } from 'react-router-dom';
import { FaLock } from "react-icons/fa6";
import { VALIDATION_ALERT_MESSAGES } from '../../Helper/constants';
import SuccessPopup from '../Dlom/SuccessPopupMessage';
import RefusedPopup from '../Dlom/RefusedPopupMessage';


export default function NewPassword() {
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordNotMatch, setPasswordNotMatch] = useState('');
    const { state } = useLocation();
    const { user_id } = state;
    const navigate = useNavigate();
    const [showPopup, setShowPopup] = useState(false);
    const [popupMessage, setPopupMessage] = useState('');
    const [showPopupRefused, setShowPopupRefused] = useState(false);
    const [popupRefusedMessage, setPopupRefusedMessage] = useState('');

    const newPasswordDetails = async (e) => {
        e.preventDefault();
        if (!newPassword || !confirmPassword) {
            return;
        }
        const newPasswordResponse = await UserNewPassword(newPassword, confirmPassword, user_id);
        try {
            if (newPasswordResponse['data']['client_status_code'] === 200) {
                localStorage.removeItem('access_token');
                localStorage.removeItem('refresh_token');
                localStorage.removeItem('user_id');   
                setPopupMessage(newPasswordResponse['data']['message']);
                setShowPopup(true);
                setTimeout(() => {
                    setShowPopup(false);
                    navigate('/')
                }, 2000);
            } else {
                setPasswordNotMatch(newPasswordResponse['data']['message']);
            }
        } catch (error) {
            setPopupRefusedMessage(VALIDATION_ALERT_MESSAGES.connectionRefusedAlert);
            setShowPopupRefused(true);
            setTimeout(() => {
                setShowPopupRefused(false);
            }, 2000);
        }
    }

    const forgetPasswordPage = () => {
        navigate('/forgot_password')
    }

    return (
        <div className="app">
            <div className='new-password-app'>
                <div className="auth-form-container">
                    <div className='forgetPassword-btn'>
                        New Password
                    </div>
                    <form className="login-form" onSubmit={newPasswordDetails}>
                        <div className="login-parent-inputs">
                            <div className="login-username-input">
                                <FaLock className="username-icon" />
                                <input className="new-password-input" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} type="password" placeholder='Password' required />
                            </div>
                            <div className="login-password-input">
                                <FaLock className="username-icon" />
                                <input className="new-password-input" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} type="password" placeholder='Confirm Password' required />
                            </div>
                            {passwordNotMatch && (
                                <div className="password-error-message">{passwordNotMatch}</div>
                            )}
                            <button className="login-button" type='submit' id="new-password">Update</button>
                        </div>
                    </form>
                    <span className="new-password-btn"><a className='signUp-button' onClick={forgetPasswordPage}>Back</a></span>
                    {showPopup && (
                        <SuccessPopup popupMessage={popupMessage} />
                    )}
                    {showPopupRefused && (
                        <RefusedPopup popupRefusedMessage={popupRefusedMessage} />
                    )}
                </div>
            </div>
        </div>
    );
}

