import React from 'react';
import { useLocation } from 'react-router-dom';
import TopNavbar from './TopNavbar';
import LeftNavbar from './LeftNavbar';
import { Container, Row, Col, Card, CardBody, CardTitle, Table, CardHeader } from 'reactstrap';


export default function UserProfile() {
  const { state: user_details } = useLocation();
  const { username, firstName, lastName } = user_details;

  const handleSelectChange = () => {
    return;
  };

  return (
    <div className="app-container">
      <LeftNavbar onSelectChange={handleSelectChange}/>
      <div className="main-container">
        <TopNavbar onSelectChange={handleSelectChange}/>
        <Container fluid className="content">
          <Row>
            <Col md={11} className="dashboard">
              <CardTitle tag="h1" className='user-profile-name'>Welcome to {firstName} {lastName}</CardTitle>
              <br />
              <Card>
                <CardHeader>
                  <h5 className='calculation-title-size'>User Profile Details</h5>
                </CardHeader>
                <CardBody>
                  <Table striped>
                    <tbody>
                      <tr>
                        <th>Username:</th>
                        <td>{username}</td>
                      </tr>
                      <tr>
                        <th>Firstname:</th>
                        <td>{firstName}</td>
                      </tr>
                      <tr>
                        <th>Lastname:</th>
                        <td>{lastName}</td>
                      </tr>
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

