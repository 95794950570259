import React, { useState, useEffect } from 'react'
import LeftNavbar from './LeftNavbar';
import TopNavbar from './TopNavbar';
import { GetAccessToken } from '../../ServerApi/server';
import { DlomValues } from '../../ServerApi/server';
import DlomResultTables from './DlomResultTables';
import { BsFillSaveFill } from "react-icons/bs";
import { ExportCalculationValues } from '../../ServerApi/server';
import { FaDownload } from "react-icons/fa";
import DlomLiquidationPeriodTable from './DlomLiquidationPeriodTable';
import RefusedPopup from './RefusedPopupMessage';
import LoadingScreen from './LoadingScreen';
import DatePicker from 'react-datepicker';
import { parseISO } from 'date-fns';
import 'react-datepicker/dist/react-datepicker.css';
import { minDate, maxDate, dlomCalculationExportFileName, blockageCalculationExportFileName } from '../../Helper/helpers';
import { Button, Form, FormGroup, Label, Input, Container, Row, Col, Card, CardBody, Tooltip, CardHeader } from 'reactstrap';
import { VALIDATION_ALERT_MESSAGES } from '../../Helper/constants';


export default function Dashboard() {
  const [yearMonth, setYearMonth] = useState('');
  const [marketValue, setMarketValue] = useState('')
  const [blockSize, setBlockSize] = useState('')
  const [sicCode, setSICCode] = useState('')
  const [showPopupRefused, setShowPopupRefused] = useState(false);
  const [popupRefusedMessage, setPopupRefusedMessage] = useState('');
  const [blockSizeTooltip, setBlockSizeTooltip] = useState(false);
  const toggleBlockSizeTooltip = () => setBlockSizeTooltip(!blockSizeTooltip);
  const [sicCodeTooltip, setSicCodeTooltip] = useState(false);
  const toggleSicCodeTooltip = () => setSicCodeTooltip(!sicCodeTooltip)
  const [loginApiResponse, setLoginApiResponse] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [calculation, setCalculation] = useState(true);
  const [isRendered, setIsRendered] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);

  const handleDateChange = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const yearMonthDate = `${year}-${month}`;

    setYearMonth(yearMonthDate);
    setShowCalendar(false);
  };

  const [errors, setErrors] = useState({
    yearMonth: '',
    blockSize: '',
    sicCode: ''
  });

  const validateForm = () => {
    let valid = true;
    const newErrors = {
      yearMonth: '',
      blockSize: '',
      sicCode: ''
    };

    const isValidFormat = /^[0-9]{4}-[0-9]{2}$/.test(yearMonth);
    const invalidCharsRegex = /[^0-9.%]/;

    if (!isValidFormat) {
      setYearMonth('');
      newErrors.yearMonth = VALIDATION_ALERT_MESSAGES.invalidDateFormat;
      valid = false;
    }

    if (!yearMonth) {
      newErrors.yearMonth = VALIDATION_ALERT_MESSAGES.yearMonthValidator;
      valid = false;
    }

    if (yearMonth && (yearMonth < minDate || yearMonth > maxDate)) {
      setYearMonth('');
      newErrors.yearMonth = VALIDATION_ALERT_MESSAGES.invalidDateAlert;
      valid = false;
    }

    if (!blockSize) {
      newErrors.blockSize = VALIDATION_ALERT_MESSAGES.blockSizeValidator;
      valid = false;
    } else if (!/^\d+(\.\d+)?%?$/.test(blockSize) || invalidCharsRegex.test(blockSize)) {
      newErrors.blockSize = VALIDATION_ALERT_MESSAGES.invalidBlockSizeAlert;
      valid = false;
    }

    if (!marketValue) {
      newErrors.marketValue = VALIDATION_ALERT_MESSAGES.marketValueValidator;
      valid = false;
    }

    if (!sicCode) {
      newErrors.sicCode = VALIDATION_ALERT_MESSAGES.sicCodeValidator;
      valid = false;
    } else if (sicCode < 1 || sicCode > 99) {
      newErrors.sicCode = VALIDATION_ALERT_MESSAGES.sicCodeAlert;
      valid = false;
    }

    setErrors(newErrors);

    return valid;
  };

  const handleSelectChange = async (value) => {
    setIsRendered(true);
    setCalculation(value);
  };

  useEffect(() => {
    if (isRendered) GetDlomResult();
  }, [calculation]);

  const GetDlomResult = async () => {
    if (!validateForm()) {
      return;
    }
    setIsLoading(true);

    const calculationResultResponse = await DlomValues(yearMonth, marketValue, blockSize, sicCode, calculation);
    try {
      setIsLoading(false);
      if (calculationResultResponse['data'] && calculationResultResponse['data']['client_status_code'] === 200) {
        setLoginApiResponse(calculationResultResponse);
      } else if (calculationResultResponse['data'] && calculationResultResponse['data']['client_status_code'] === 400) {
        setPopupRefusedMessage(calculationResultResponse['data']['message']);
        setShowPopupRefused(true);
        setTimeout(() => {
          setShowPopupRefused(false);
        }, 2000)
      } else if (calculationResultResponse['response'] && calculationResultResponse['response']['data']['client_status_code'] === 401) {
        let refreshToken = localStorage.getItem('refresh_token');
        const GetAccessTokenResponse = await GetAccessToken(refreshToken);
        localStorage.setItem('access_token', GetAccessTokenResponse['data']['data']['access_token']);
        GetDlomResult()
      } else {
        setPopupRefusedMessage(VALIDATION_ALERT_MESSAGES.tryAgainAlert);
        setShowPopupRefused(true);
        setTimeout(() => {
          setShowPopupRefused(false);
        }, 2000)
      }
    } catch (error) {
      setPopupRefusedMessage(VALIDATION_ALERT_MESSAGES.connectionRefusedAlert);
      setShowPopupRefused(true);
      setTimeout(() => {
        setShowPopupRefused(false);
      }, 2000);
    }
  }

  const exportDlomCalculationResult = async () => {

    if (!validateForm()) {
      return;
    }
    setIsLoading(true);
    const exportCalculationResponse = await ExportCalculationValues(yearMonth, marketValue, blockSize, sicCode, calculation);
    try {
      setIsLoading(false);
      if (exportCalculationResponse['status'] === 200) {
        const csvData = exportCalculationResponse['data'];
        const blob = new Blob([csvData], { type: 'text/csv' });
        const url = URL.createObjectURL(blob);
        const anchor = document.createElement('a');

        anchor.href = url;

        anchor.download = calculation ? blockageCalculationExportFileName : dlomCalculationExportFileName;

        document.body.appendChild(anchor);
        anchor.click();

        document.body.removeChild(anchor);
        URL.revokeObjectURL(url);

      } else if (exportCalculationResponse['response'] && exportCalculationResponse['response']['data']['client_status_code'] === 401) {
        let refreshToken = localStorage.getItem('refresh_token');
        const accessTokenResponse = await GetAccessToken(refreshToken);
        localStorage.setItem('access_token', accessTokenResponse['data']['data']['access_token']);
        exportDlomCalculationResult()
      } else {
        setPopupRefusedMessage(VALIDATION_ALERT_MESSAGES.tryAgainAlert);
        setShowPopupRefused(true);
        setTimeout(() => {
          setShowPopupRefused(false);
        }, 2000)
      }
    } catch (error) {
      setPopupRefusedMessage(VALIDATION_ALERT_MESSAGES.connectionRefusedAlert);
      setShowPopupRefused(true);
      setTimeout(() => {
        setShowPopupRefused(false);
      }, 2000);
    }
  }

  let tableGroups = [];

  if (loginApiResponse?.data) {
    tableGroups = [
      [
        { title: "Discount AAEX Option Table", data: loginApiResponse.data.market_value_portfolio_aaex_option },
        { title: "Discount MEX Option Table", data: loginApiResponse.data.market_value_portfolio_mex_option },
        { title: "Discount LBEX Option Table", data: loginApiResponse.data.market_value_portfolio_lbex_option }
      ],
      [
        { title: "Discount AAEX Option Table", data: loginApiResponse.data.industry_portfolio_aaex_option },
        { title: "Discount MEX Option Table", data: loginApiResponse.data.industry_portfolio_mex_option },
        { title: "Discount LBEX Option Table", data: loginApiResponse.data.industry_portfolio_lbex_option }
      ],
      [
        { title: "Discount AAEX Option Table", data: loginApiResponse.data.intersection_portfolio_aaex_option },
        { title: "Discount MEX Option Table", data: loginApiResponse.data.intersection_portfolio_mex_option },
        { title: "Discount LBEX Option Table", data: loginApiResponse.data.intersection_portfolio_lbex_option }
      ],
    ];
  }

  let portfolioTables = []

  if (loginApiResponse?.data) {
    portfolioTables = [
      [{ key: loginApiResponse?.data?.market_value_portfolio_freq?.freq || 'N/A', title: `Peer Group Values (Portfolio  Size = ${loginApiResponse?.data?.market_value_portfolio_freq?.freq || 'N/A'})`, data: loginApiResponse.data.market_value_portfolio_table }],
      [{ key: loginApiResponse?.data?.industry_portfolio_freq?.freq || 'N/A', title: `Peer Group Values (Portfolio  Size = ${loginApiResponse?.data?.industry_portfolio_freq?.freq || 'N/A'})`, data: loginApiResponse.data.industry_portfolio_table }],
      [{ key: loginApiResponse?.data?.intersection_portfolio_freq?.freq || 'N/A', title: `Peer Group Values (Portfolio  Size = ${loginApiResponse?.data?.intersection_portfolio_freq?.freq || 'N/A'})`, data: loginApiResponse.data.intersection_portfolio_table }]
    ]
  }

  const titles = [
    `MV Portfolio${loginApiResponse?.data?.market_value_portfolio_aaex_option ? ` (Decile ${loginApiResponse?.data?.decile_size || 'N/A'})` : ''}`,
    `Industry Portfolio ${loginApiResponse?.data?.industry_portfolio_aaex_option ? ` (SIC Group ${loginApiResponse?.data?.sic_code || 'N/A'})` : ''}`,
    `Intersection Portfolio${loginApiResponse?.data?.intersection_portfolio_aaex_option ? ` (Decile ${loginApiResponse?.data?.decile_size || 'N/A'}) and (SIC Group ${loginApiResponse?.data?.sic_code || 'N/A'})` : ''}`
  ];

  const getTitle = (index) => {
    return titles[index] || `Group ${index + 1}`;
  };

  const handleClickOutside = () => {
    setShowCalendar(false);
  };

  return (
    <div className="app-container">
      <LeftNavbar onSelectChange={handleSelectChange} />
      <div className="main-container">
        <TopNavbar onSelectChange={handleSelectChange} />
        <Container fluid className="content">
          <Row>
            <Col md={11} className="dashboard">
              {(calculation == true) && (
                <h5 className="calculator">Blockage Calculator</h5>
              )}
              {calculation == false && (
                <h5 className="calculator">DLOM Calculator</h5>
              )}
              <h5 className="year-month">Dr. Ashok Abbott</h5>
              <br />
              <Form>
                <Card>
                  <CardHeader className="card-header">
                    <div className="calculation-output-csv">
                      <h5 className='calculation-title-size'>Basic Calculation Input Fields</h5>
                      {loginApiResponse && loginApiResponse.data && (loginApiResponse.data.market_value_portfolio_aaex_option || loginApiResponse.data.industry_portfolio_aaex_option || loginApiResponse.data.intersection_portfolio_aaex_option) && (
                        <Button className="calculator-submit" type="button" title="Export CSV" onClick={exportDlomCalculationResult}>
                          <FaDownload className="table-icon" />
                          <span>Export</span>
                        </Button>
                      )}
                    </div>
                  </CardHeader>
                  <CardBody>
                    <FormGroup>
                      <Label>Year and Month:</Label>
                      <Input
                        type="text"
                        placeholder="YYYY-MM"
                        value={yearMonth}
                        onFocus={() => setShowCalendar(true)}
                        onChange={(e) => setYearMonth(e.target.value)}
                        required
                      />
                      {showCalendar && (
                        <div className="year-month-calendar">
                          <DatePicker
                            selected={yearMonth ? parseISO(yearMonth) : null}
                            onChange={handleDateChange}
                            minDate={new Date(minDate)}
                            maxDate={new Date(maxDate)}
                            dateFormat="yyyy-MM"
                            showMonthYearPicker
                            inline
                            onClickOutside={handleClickOutside}
                            className="custom-datepicker"
                          />
                        </div>
                      )}
                      {errors.yearMonth && (
                        <div className="text-danger">{errors.yearMonth}</div>
                      )}
                    </FormGroup>
                    <FormGroup>
                      <Label>Market Value:</Label>
                      <Input
                        type="number"
                        value={marketValue}
                        onChange={(e) => setMarketValue(e.target.value)}
                      />
                      {errors.marketValue && (
                        <div className="text-danger">{errors.marketValue}</div>
                      )}
                    </FormGroup>
                    <FormGroup>
                      <Label for="sicCode">SIC Code:</Label>
                      <Input
                        type="number"
                        id="sicCode"
                        name="sicCode"
                        maxLength="2"
                        value={sicCode}
                        onChange={(e) => {
                          const sicCodeInputValue = e.target.value.replace(/\D/g, '').slice(0, 2);
                          setSICCode(sicCodeInputValue);
                        }}
                      />
                      {errors.sicCode && (
                        <div className="text-danger">{errors.sicCode}</div>
                      )}
                      <Tooltip
                        placement="top"
                        isOpen={sicCodeTooltip}
                        autohide={false}
                        target="sicCode"
                        toggle={toggleSicCodeTooltip}
                      >
                        {VALIDATION_ALERT_MESSAGES.sicCodeTooltipAlert}
                      </Tooltip>
                    </FormGroup>
                    <FormGroup>
                      <Label for="blockSize">Block Size:</Label>
                      <Input
                        type="text"
                        id="blockSize"
                        name="blockSize"
                        value={blockSize}
                        onChange={(e) => setBlockSize(e.target.value)}
                        required
                      />
                      {errors.blockSize && (
                        <div className="text-danger">{errors.blockSize}</div>
                      )}
                      <Tooltip
                        placement="top"
                        isOpen={blockSizeTooltip}
                        autohide={false}
                        target="blockSize"
                        toggle={toggleBlockSizeTooltip}
                      >
                        {VALIDATION_ALERT_MESSAGES.invalidBlockSizeAlert}
                      </Tooltip>
                    </FormGroup>
                    <div className="calculation-export-csv">
                      <Button className="calculator-submit" type="button" title="Submit" onClick={GetDlomResult}>
                        <BsFillSaveFill className="table-icon" />
                        <span>Submit</span>
                      </Button>
                    </div>
                  </CardBody>
                </Card>
              </Form><br />
              {loginApiResponse && loginApiResponse.data && loginApiResponse.data.calculation_type == false && (
                <span className="dlom-alert-message"><b>{VALIDATION_ALERT_MESSAGES.dlomCalculationAlert}</b></span>
              )}
              {loginApiResponse && loginApiResponse.data && (
                <div>
                  {tableGroups.map((group, index) => {
                    const hasData = group.some(item => item.data !== null && item.data !== undefined);
                    return (
                      <div key={`group-${index}`}>
                        <Card>
                          <CardHeader>{getTitle(index)}</CardHeader>
                          <CardBody>
                            {hasData ? (
                              <>
                                <DlomLiquidationPeriodTable size={portfolioTables[index][0].key} title={portfolioTables[index][0].title} data={portfolioTables[index][0].data} />
                                {group.map(({ title, data }, innerIndex) => (
                                  <DlomResultTables key={`result-${innerIndex}`} title={title} data={data} />
                                ))}
                              </>
                            ) : (
                              <>
                                <br />
                                <span className="dlom-alert-message">
                                  <b>
                                    <center>The {loginApiResponse.data.portfolio_list[index]} portfolio id formed with that combination is not available, and no tables can be printed.</center>
                                  </b>
                                  <br />
                                </span>
                              </>
                            )}
                          </CardBody>
                        </Card>
                        <br key={`br-${index}`} />
                      </div>
                    );
                  })}
                </div>
              )}
              {showPopupRefused && (
                <RefusedPopup popupRefusedMessage={popupRefusedMessage} />
              )}
            </Col>
          </Row>
        </Container>
        {isLoading && (
          <LoadingScreen />
        )}
      </div>
    </div>
  );
}
