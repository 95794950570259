import React, { useState } from 'react';
import TopNavbar from './TopNavbar';
import LeftNavbar from './LeftNavbar';
import { useLocation } from 'react-router-dom';
import { Container, Col, CardTitle, Card, CardHeader, CardBody, Row } from 'reactstrap';


export default function StandardIndustrialCode({ onSelectChange }) {
  const { state: sic_code_details } = useLocation();
  const sicCodeArray = sic_code_details && sic_code_details.SCI_code ? sic_code_details.SCI_code : [];
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');
  const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };

  const filteredTickers = sicCodeArray.filter((code) => {
    let sharesOutstanding = code.share_class_shares_outstanding;
    return (
      code.sic_code.toLowerCase().includes(searchTerm.toLowerCase()) ||
      code.standard_industrial_codes.toLowerCase().includes(searchTerm.toLowerCase()) ||
      (sharesOutstanding !== null &&
        sharesOutstanding !== undefined &&
        sharesOutstanding.toString().includes(searchTerm))
    );
  });

  let indexOfLastItem = currentPage * itemsPerPage;
  let indexOfFirstItem = indexOfLastItem - itemsPerPage;
  let currentTickers = filteredTickers.slice(indexOfFirstItem, indexOfLastItem);
  let totalPages = Math.ceil(filteredTickers.length / itemsPerPage);

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const maxVisiblePages = 3;
    let startPage = Math.max(currentPage - Math.floor(maxVisiblePages / 2), 1);
    let endPage = startPage + maxVisiblePages - 1;

    if (endPage > totalPages) {
      endPage = totalPages;
      startPage = Math.max(endPage - maxVisiblePages + 1, 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <li className={`page-item ${currentPage === i ? 'active' : ''}`} key={i}>
          <button className="page-link" onClick={() => handlePageChange(i)}>
            {i}
          </button>
        </li>
      );
    }

    return pageNumbers;
  };

  const handleSelectChange = () => {
    return;
  };

  return (
    <div className="app-container">
      <LeftNavbar onSelectChange={handleSelectChange} />
      <div className="main-container">
        <TopNavbar onSelectChange={handleSelectChange} />
        <Container fluid className="content">
          <Row>
            <Col md={11} className="dashboard">
              <CardTitle tag="h1" className="ticker-profile-name">
                Standard Industrial Codes
              </CardTitle>
              <br />
              {sicCodeArray.length > 0 && (
                <Card>
                  <CardHeader className="card-header-result-table" >
                    <div className="d-flex justify-content-between mb-3">
                      <div className="d-flex align-items-center">
                        <span className="ml-3">Show</span>
                        <select
                          id="pagination-entries"
                          name="entries"
                          value={itemsPerPage}
                          onChange={(e) => {
                            setItemsPerPage(Number(e.target.value));
                            setCurrentPage(1);
                          }}
                          className="form-control input-sm"
                        >
                          <option value="10">10</option>
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </select>
                        <span className="ml-2">entries</span>
                      </div>
                      <div className="search-export-csv">
                        <div>
                          <input
                            className="calculation-result-search"
                            type="text"
                            placeholder="Search"
                            value={searchTerm}
                            onChange={handleSearchChange}
                          />
                        </div>
                      </div>
                    </div>
                  </CardHeader>
                  <CardBody>
                    <div className="table-responsive">
                      <table className="table table-striped">
                        <thead>
                          <tr className="ticker-records">
                            <th>SIC Code</th>
                            <th>Standard Industrial Codes</th>
                          </tr>
                        </thead>
                        <tbody>
                          {currentTickers.map((code) => (
                            <tr key={code.id} className="ticker-records">
                              <td>{code.sic_code}</td>
                              <td>{code.standard_industrial_codes}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div className="d-flex justify-content-between mt-3">
                      <div>
                        Showing {indexOfFirstItem + 1} to {Math.min(indexOfLastItem, filteredTickers.length)} of{' '}
                        {filteredTickers.length} entries
                      </div>
                      <div>
                        <nav aria-label="Page navigation example">
                          <ul className="pagination justify-content-end">
                            <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                              <button
                                className="page-link"
                                onClick={() => handlePageChange(currentPage - 1)}
                                disabled={currentPage === 1}
                              >
                                Previous
                              </button>
                            </li>
                            {renderPageNumbers()}
                            <li
                              className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}
                            >
                              <button
                                className="page-link"
                                onClick={() => handlePageChange(currentPage + 1)}
                                disabled={currentPage === totalPages}
                              >
                                Next
                              </button>
                            </li>
                          </ul>
                        </nav>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              )}<br />
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

