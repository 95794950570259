import React, { useState } from 'react';
import { UserForgetPassword } from '../../ServerApi/server'
import { useNavigate } from 'react-router-dom';
import { IoMailSharp } from "react-icons/io5";
import { VALIDATION_ALERT_MESSAGES } from '../../Helper/constants';
import RefusedPopup from '../Dlom/RefusedPopupMessage';


export default function ForgetPassword() {
    const [registerEmail, setRegisterEmail] = useState('');
    const [errorEmail, setErrorEmail] = useState('')
    const navigate = useNavigate();
    const [showPopupRefused, setShowPopupRefused] = useState(false);
    const [popupRefusedMessage, setPopupRefusedMessage] = useState('');

    const forgetPasswordDetails = async (e) => {
        e.preventDefault();
        if (!registerEmail) {
            return;
        }
        
        try {
            const forgetPasswordResponse = await UserForgetPassword(registerEmail);
            if (forgetPasswordResponse['data']['client_status_code'] === 200) {
                navigate('/new_password', { state: { user_id: forgetPasswordResponse['data']['user_id'] } })
            } else {
                setErrorEmail(forgetPasswordResponse['data']['message']);
            }
        } catch (error) {
            setPopupRefusedMessage(VALIDATION_ALERT_MESSAGES.connectionRefusedAlert);
            setShowPopupRefused(true);
            setTimeout(() => {
                setShowPopupRefused(false);
            }, 2000);
        }
    }

    const loginPage = () => {
        navigate('/')
    }

    return (
        <div className="app">
            <div className='login-app'>
                <div className="auth-form-container">
                    <div className='forgetPassword-btn'>
                        Forgot Password?
                    </div>
                    <form className="login-form" onSubmit={forgetPasswordDetails}>
                        <div className="login-parent-inputs">
                            <div className="login-username-input">
                                <IoMailSharp className="username-icon" />
                                <input className="forget-input" value={registerEmail} onChange={(e) => setRegisterEmail(e.target.value)} type="email" placeholder='Email' required />
                            </div>
                            {errorEmail && (
                                <div className="password-error-message">{errorEmail}</div>
                            )}
                            <button className="login-button" type='submit'>Submit</button>
                        </div>
                    </form>
                    <span className="link-btn"><a className='signUp-button' onClick={loginPage}>Back</a></span>
                    {showPopupRefused && (
                        <RefusedPopup popupRefusedMessage={popupRefusedMessage} />
                    )}
                </div>
            </div>
        </div>
    );
}